// Footer base

.app-footer {
  // height: $app-header-height;

  .app-footer__inner {
    // padding: 0 $layout-spacer-x 0 ($layout-spacer-x / 2);
    // height: $app-header-height;
    transition: all 0.2s;

    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    .app-footer-left {
      display: flex;
      align-items: center;
    }

    .app-footer-right {
      margin-left: auto;
      display: flex;
    }

    .app-footer-center {
      display: flex;
    }
  }
}

// Footer Dots Section

.footer-dots {
  display: flex;
  align-items: center;
  align-content: center;

  .dots-separator {
    height: 40px;
    margin: 0 ($layout-spacer-x / 2.5);
    width: 1px;
    background: $gray-200;
  }
}

.dot-btn-wrapper {
  padding: 0.2rem 0.5rem;
  // padding: 0.5rem;
  position: relative;
  display: flex;
  // opacity: 0.7;
  opacity: 1;
  transition: opacity 0.2s;

  //added style
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: transparent;
  // box-shadow: none;
  border: 0;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;

  button:hover,
  button:focus {
    outline: none;
  }

  .badge-abs {
    right: 50%;

    &.badge-dot-sm {
      top: -2px;
      margin-right: -3px;
    }
  }

  .dot-btn-icon {
    font-size: 1.8rem;
  }

  &:hover {
    text-decoration: none;
    opacity: 1;
  }
}

// Footer Modifiers

@import "fixed-footer";
