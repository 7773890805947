// Fixed Header

.fixed-footer {
  .app-footer {
    // position: sticky;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1000;
    // z-index: 7;

    .app-footer__inner {
      // margin-left: $app-sidebar-width;
      // box-shadow: 0.3rem -0.46875rem 2.1875rem rgba(darken($primary, 50%), 0.02),
      //   0.3rem -0.9375rem 1.40625rem rgba(darken($primary, 50%), 0.02),
      //   0.3rem -0.25rem 0.53125rem rgba(darken($primary, 50%), 0.04),
      //   0.3rem -0.125rem 0.1875rem rgba(darken($primary, 50%), 0.02);
      box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.56);
    }
  }

  .app-main .app-main__outer {
    padding-bottom: $app-header-height;
  }

  &.closed-sidebar {
    .app-footer {
      .app-footer__inner {
        // margin-left: $app-sidebar-width-collapsed !important;
      }
    }
  }
}
